
function xy() {
	const validArray = ['/seria','/triedy','/diplomy','/oblubene-kurzy','/profil', '/pomocnik']
	const serviceUrl = localStorage.getItem('EXTERNAL_URL_CHECK')
	let checker = null
	validArray.forEach( (i) => {
		if(serviceUrl.includes(i)) checker = true
	})
	if(checker==true) return true
	else return false
}
const mainteinc = xy()
export default mainteinc

