<template>
	<form @keydown.enter="login()" class="mb-5">
		<div class="register-info p-3">
			<img src="../_layouts/default/img/info-icon.png" />
			<p class="font-weight-bold">
				Učitelia, vytvorte si online triedy a zapojte sa do SÚŤAŽE. Viac info v sekcii "Moje triedy" vo vašom Profile.
				<!-- <a href="https://drive.google.com/file/d/1Ka2J30IWcFyR6FI4tWtX2yIYDpzUVZMk/view?usp=sharing" target="_blank" class="link">
					Viac info
				</a> -->
			</p>
		</div>

		<sup dev @click.prevent="dev_prefill()">Prefill</sup>

		<div class="row mb-0">
			<div class="form-group col-md-6 col-sm-12">
				<label for="emailAddress">Emailová adresa</label>
				<input v-model="credentials.login" type="email" class="form-control" placeholder="Zadajte email" autocomplete="email">

				<div class="support-section sign-in-prolem mt-4">
					<icon-help-circle-svg class="sign-in-prolem__icon mr-2"/>
					<p class="mb-0">
						<span class="mr-1">Problém s prihlásením? <br class="sign-in-prolem__break" />Napíšte na</span>
						<a href="mailto:team@zmudri.sk">team@zmudri.sk</a>
					</p>
				</div>
			</div>

			<div class="form-group col-md-6 col-sm-12">
				<label for="passwordConfirmation">Heslo</label>
				<input v-model="credentials.password" type="password" class="form-control" placeholder="Zadajte heslo" autocomplete="current-password">

				<div class="support-section forgot-password mt-4" @click="navToForgotPassword">
					<icon-lock class="forgot-icon"/>
					Zabudol/a som heslo
				</div>
			</div>
		</div>

		<div class="d-flex flex-wrap flex-column flex-lg-row align-items-stretch align-items-lg-center">
			<b-button variant="primary" @click.prevent="login()" :disabled="$wToast.isLoading()" class="mr-0 mr-lg-2 mt-3">Prihlásiť sa</b-button>
			<b-button variant="secondary" @click.prevent="navToRegistration" class="mr-0 mt-3">Registrácia - nové konto</b-button>
		</div>
		
	</form>
</template>

<script>
import wToastUtils from '@/plugins/w/toast/utils'
import routerServices from '@/plugins/appzmudri/router.service.ts'

export default {
	components: {
		'icon-lock': () => import('./img/lock-closed.svg?inline'),
		'icon-help-circle-svg': () => import('@/plugins/appzmudri/_theme/icon/help-circle.svg?inline')
	},
	props: {
		showHeader: {
			type: Boolean,
			required: false,
			default: true
		} 
	},
	computed: {
		userType() {
			const user = this.$store.getters['wAuth/user']
			return user && user.profile && user.profile.type
		},
	},

	data() {
		return {
			credentials: {
				login: '',
				password: ''
			}
		}
	},

	methods: {
		async login() {
			// this.$wToast.clear_loading()
			// this.$wEvent.userLog('user.login', {timestamp: new Date()})
			console.log("TETSETES2")
			try {
				await this.$store.dispatch('wAuth/login', this.credentials)
				this.$wToast.stopLoading()
				const lastRoute = localStorage.getItem('LAST_FROM_ROUTE')
				const validateRoute = localStorage.getItem('EXTERNAL_URL_CHECK')
				if(routerServices==true){
					window.location.href = validateRoute
				}
				else if(lastRoute){
					this.$router.push(lastRoute)
				}
				else if(!lastRoute && this.$route.fullPath == '/prihlasit-sa')  {
					this.$router.push(this.userType == 'student' ? '/oblubene-kurzy' : '/pomocnik')
				}
				else {
					await this.$router.push('/')
				}
				localStorage.removeItem('LAST_FROM_ROUTE')
				localStorage.removeItem('EXTERNAL_URL_CHECK')
				this.$emit('afterLogin')
			} 
			catch (error) {
				const str = wToastUtils.parseErrorsToArrayOfStrings(error).map(
					err => {
						return err.replace(/(.*)(\[.+\])(.*)/, function(match, p1, p2, p3) {
							return p1 + '[*]' + p3
						})
					}
				)
				this.$wToast.error(str)
			}
		},

		navToRegistration() {
			if(this.$route.path != '/registracia')
				this.$router.push('/registracia')
			this.$emit('afterRegistration')
		},

		navToForgotPassword() {
			if(this.$route.path != '/prihlasit-sa/zabudnute-heslo')
				this.$router.push(this.CONFIG.w.wLogin.routeForgot || '/prihlasit-sa/zabudnute-heslo')
			this.$emit('afterForgotPassword')
		},


		dev_prefill() {
			this.credentials = {
				login: 'testing@wezeo.com',
				password: '12345678'
			}
		}
	}
}
</script>
<style lang="scss" scoped>
form {
	font-family: 'Poppins', sans-serif;
	white-space: normal;
}

.register-info {
	font-family: 'Poppins';
	max-width: 467px;
	background: #FFF5D1;
	border: 1px solid #E5D8A7;
	border-radius: 4px;
	margin: 20px auto;
	display: flex;
	align-items: center;

	img {
		margin-right: 30px;
	}

	p {
		color: #998C5C;
		white-space: normal;
		margin: 0;

		a.link {
			font-weight: 700;
			color: inherit;
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: 480px){
		max-width: 20em;
	}
}

.support-section {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.75rem;

	& * {
		font-size: 0.75rem;
	}
}

.forgot-password{
	cursor: pointer;

	.forgot-icon {
		width: 25px;
		height: 25px;
		margin: 0px 5px 7px 0px;
	}

	@media screen and (max-width: 384px) {
		margin-top: 10px;
	}
}

.sign-in-prolem {
	&,
	*{
		color: var(--a-color-secondary);
	}

	.sign-in-prolem__icon {
		width: 24px;
		min-width: 24px;
		height: 24px;
		min-height: 24px;
	}

	.sign-in-prolem__break {
		@media screen and (min-width: 576px) {
			display: none;
		}

		@media screen and (min-width: 768px) and (max-width: 1400px) {
			display: inline;
		}
	}

	a {
		font-weight: 700;
		text-decoration: underline;
	}
}
</style>